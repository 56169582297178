<mat-card class="user-working-hours-selected-list-container">

  <mat-card-header>

    <mat-card-title>Riepilogo attività</mat-card-title>

  </mat-card-header>

  <mat-card-content>

    <ng-container
      *ctSkeleton="loading;
           repeat: 3;
           className: 'activity-chip-skeleton';">

      <ng-container *ngIf="list.length; else emptyList">

        <mat-chip-list>

          <mat-chip
            [selectable]="false"
            [disableRipple]="true"
            *ngFor="let activity of list"
            [removable]="true"
            (removed)="remove(activity)">
            <button mat-icon-button matChipAvatar (click)="notes(activity)">
              <mat-icon color="accent">note_alt</mat-icon>
            </button>
            <div class="chip-content">
              <div>{{ activity.Hours }}H {{ activity.ActivityType.Name }} </div>
              <div><small *ngIf="activity?.WorkOrder?.Name"> Cantiere: {{ activity?.WorkOrder?.Name }}</small></div>
              <div><small
                *ngIf="activity?.DictionaryInfo?.Name"> Veicolo: {{ activity?.DictionaryInfo?.Name }}</small></div>
            </div>
            <mat-icon matChipRemove color="warn">cancel</mat-icon>
          </mat-chip>

        </mat-chip-list>

      </ng-container>

      <ng-template #emptyList><p>Nessuna attività registrata.</p></ng-template>

    </ng-container>

  </mat-card-content>

</mat-card>
