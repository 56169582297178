import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UserWorkingHoursWizardComponent} from "./user-working-hours-wizard.component";
import {MatLegacyCardModule} from "@angular/material/legacy-card";
import {MatLegacyButtonModule} from "@angular/material/legacy-button";
import {MatIconModule} from "@angular/material/icon";
import {FlexLayoutModule} from "@angular/flex-layout";
import {MatLegacyListModule} from "@angular/material/legacy-list";
import {
  UserWorkingHoursWizardFormComponent
} from './user-working-hours-wizard-form/user-working-hours-wizard-form.component';
import {
  UserWorkingHoursSelectedListComponent
} from './user-working-hours-selected-list/user-working-hours-selected-list.component';
import {CtButtonModule, CtControlModule, CtSkeletonModule} from "@ctsolution/ct-framework";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {
  UserWorkingHoursWorkOrderControlComponent
} from './user-working-hours-wizard-form/user-working-hours-work-order-control/user-working-hours-work-order-control.component';
import {
  UserWorkingHoursActivityTypeControlComponent
} from './user-working-hours-wizard-form/user-working-hours-activity-type-control/user-working-hours-activity-type-control.component';
import {
  UserWorkingHoursHoursControlComponent
} from './user-working-hours-wizard-form/user-working-hours-hours-control/user-working-hours-hours-control.component';
import {UserWorkingHoursWizardService} from "./user-working-hours-wizard.service";
import {
  UserWorkingHoursUserInfoControlComponent
} from './user-working-hours-wizard-form/user-working-hours-user-info-control/user-working-hours-user-info-control.component';
import {MatLegacyDialogModule} from "@angular/material/legacy-dialog";
import {MatLegacyChipsModule} from "@angular/material/legacy-chips";
import {
  UserWorkingHoursNotesControlComponent
} from './user-working-hours-wizard-form/user-working-hours-notes-control/user-working-hours-notes-control.component';
import {
  UserWorkingHoursNotesDialogComponent
} from './user-working-hours-selected-list/user-working-hours-notes-dialog/user-working-hours-notes-dialog.component';
import {
  UserWorkingHoursDictionaryInfoControlComponent
} from './user-working-hours-wizard-form/user-working-hours-dictionary-info-control/user-working-hours-dictionary-info-control.component';
import {MatCheckboxModule} from "@angular/material/checkbox";

@NgModule({
  declarations: [
    UserWorkingHoursWizardComponent,
    UserWorkingHoursWizardFormComponent,
    UserWorkingHoursSelectedListComponent,
    UserWorkingHoursWorkOrderControlComponent,
    UserWorkingHoursActivityTypeControlComponent,
    UserWorkingHoursHoursControlComponent,
    UserWorkingHoursUserInfoControlComponent,
    UserWorkingHoursNotesControlComponent,
    UserWorkingHoursNotesDialogComponent,
    UserWorkingHoursDictionaryInfoControlComponent
  ],
  imports: [
    CommonModule,
    MatLegacyCardModule,
    MatLegacyButtonModule,
    MatIconModule,
    FlexLayoutModule,
    MatLegacyListModule,
    CtButtonModule,
    FormsModule,
    ReactiveFormsModule,
    CtControlModule,
    MatLegacyDialogModule,
    MatLegacyChipsModule,
    CtSkeletonModule,
    MatCheckboxModule
  ],
  exports: [
    UserWorkingHoursWizardComponent
  ],
  providers: [UserWorkingHoursWizardService]
})
export class UserWorkingHoursWizardModule {
}
